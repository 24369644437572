<template>
  <div class="personalCertificate">
    <img class="authImgSrc" :src="authImgSrc" alt="认证图片">
    <Details-page :column="2" :details-title="'个人详情'" :detail-obj="detailsObj" :details-item-arr="detailsItemArr" />
    <div v-if="detailsObj&&detailsObj.authState==='0'" class="button">
      <el-button type="primary" size="small" @click="personalCertificate">
        个人认证
      </el-button>
    </div>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage.vue'
import { getUserDetail } from '@/api/login.js'
import { userAuth } from '@/api/enterpriseInformation.js'
export default {
  components: { DetailsPage },
  data() {
    return {
      detailsObj: {},
      breadcrumbItem: [{ path: '/', name: '首页' }, { path: '', name: '个人中心' }],
      authImgSrc: require('@/assets/image/weirenzheng.png'),
      detailsItemArr: [
        { label: '真实姓名', value: 'userName' },
        { label: '用户名称', value: 'userCode' },
        { label: '性别', value: 'sex', child: [{ id: '1', val: '男' }, { id: '0', val: '女' }] },
        { label: '手机', value: 'phoneNo' },
        { label: '邮箱', value: 'email' },
        { label: '身份证号', value: 'cardNo' }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getUserDetail(res => {
        this.detailsObj = { ...res.data }
        this.authImgSrc = this.detailsObj.authState === '0' ? require('@/assets/image/weirenzheng.png') : require('@/assets/image/yirenzheng.png')
      })
    },
    personalCertificate() {
      userAuth({ userCode: this.detailsObj.userCode || '' }, res => {
        this.$confirm('个人实名认证是否完成？', '提示', {
          confirmButtonText: '已完成',
          cancelButtonText: '未完成',
          type: 'success'
        }).then(() => {
          this.init()
        }).catch(() => {
          this.init()
        })
        window.open(res.data)
      })
    }
  }
}
</script>

<style lang="scss">
.personalCertificate{
  position: relative;
  .button{
    margin-top: 12px;
    text-align: center;
  }
  .authImgSrc{
    position: absolute;
    height: 120px;
    width: 120px;
    top: 20px;
    right: 120px;
    z-index: 12;
  }
}
</style>
